import styled, {css} from 'styled-components';

import {gridGutters} from '@/theme/grid';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
} from '@/theme/mediaQueries';

import {SectionGutterType} from '.';

const SECTION_GUTTER_SIZES = {
  small: gridGutters(1),
  medium: gridGutters(2),
  large: gridGutters(4),
  article: gridGutters(5),
};

interface Props {
  $size: SectionGutterType;
  $justLeft?: boolean;
  $justRight?: boolean;
}

export const StyledSectionGutter = styled.div<Props>`
  @media ${QUERY_GREATER_THAN_MOBILE} {
    ${({$justLeft, $justRight, $size}) => css`
      ${$justLeft
        ? 'padding-left:'
        : $justRight
        ? 'padding-right:'
        : `padding: 0 `} ${SECTION_GUTTER_SIZES[
        $size === 'article' ? 'large' : $size
      ]};
    `}
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    ${({$justLeft, $justRight, $size}) => css`
      ${$justLeft
        ? 'padding-left:'
        : $justRight
        ? 'padding-right:'
        : `padding: 0 `} ${SECTION_GUTTER_SIZES[$size]};
    `}
  }
`;
