import ArticleListItem from '@/components/ArticleListItem';
import {ArticleListItem as ContentfulArticleListItem} from '@/content/cms/types';

import {ArticlesWrapper} from './styles';

interface Props {
  className?: string;
  articles: ContentfulArticleListItem[];
  smallTopMargin?: boolean;
  removeBottomMargin?: boolean;
}

const Articles = ({
  className,
  articles,
  smallTopMargin,
  removeBottomMargin,
}: Props) => (
  <ArticlesWrapper
    className={className}
    $smallTopMargin={smallTopMargin}
    $removeBottomMargin={removeBottomMargin}
  >
    {articles.map((article) => (
      <ArticleListItem key={article.slug} article={article} />
    ))}
  </ArticlesWrapper>
);

export default Articles;
