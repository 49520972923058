import styled from 'styled-components';

import {FullWidthMediaWrapper} from '@/components/FullWidthMedia/styles';
import {YouTubeVideoWrapper} from '@/components/YouTubeVideo/styles';
import {gridGutters} from '@/theme/grid';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
} from '@/theme/mediaQueries';
import {SPACING_L, SPACING_M, SPACING_XL} from '@/theme/spacings';

export const ArticlePageContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  /* Remove first block child margin */
  > *:first-child > *:first-child {
    margin-top: 0;
  }

  > * {
    margin-top: ${SPACING_L};

    @media ${QUERY_GREATER_THAN_TABLET} {
      margin-top: ${SPACING_XL};
    }
  }

  > ${FullWidthMediaWrapper}, > ${YouTubeVideoWrapper} {
    @media ${QUERY_GREATER_THAN_MOBILE} {
      min-width: ${gridGutters(15)};
      align-self: center;
    }

    @media ${QUERY_GREATER_THAN_TABLET} {
      min-width: ${gridGutters(18)};
    }
  }
`;

export const FootnotesAndTopicsWrapper = styled.div`
  display: flex;
  gap: ${SPACING_M};
  flex-direction: column;

  @media ${QUERY_GREATER_THAN_TABLET} {
    flex-direction: row;
    gap: ${gridGutters(1)};
  }
`;
