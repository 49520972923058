import Blocks from '@/components/Blocks';
import Footnotes from '@/components/Footnotes';
import Topics from '@/components/Topics';
import {Article, Link} from '@/content/cms/types';
import {useGlobals} from '@/contexts/globals';

import {ArticlePageContentWrapper, FootnotesAndTopicsWrapper} from './styles';

interface Props {
  article: Article;
  links: Link[];
}

const ArticlePageHeader = ({article, links}: Props) => {
  const {articleFootnotesHeader, articleTopicsHeader} = useGlobals();
  return (
    <ArticlePageContentWrapper>
      <Blocks blocks={article.blocksCollection.items} />
      <FootnotesAndTopicsWrapper>
        {!!links?.length && (
          <Footnotes heading={articleFootnotesHeader} links={links} />
        )}
        <Topics heading={articleTopicsHeader} article={article} />
      </FootnotesAndTopicsWrapper>
    </ArticlePageContentWrapper>
  );
};

export default ArticlePageHeader;
