import DividerCopy from '@/components/DividerCopy';
import UsersCopy from '@/components/UsersCopy';
import {getArticlePath} from '@/config/routes';
import {eventClickArticle, eventClickFeaturedArticle} from '@/config/tracking';
import {ArticleListItem as ContentfulArticleListItem} from '@/content/cms/types';
import {dateIsoToReadable} from '@/utils/date';

import SkipInitialAnimatePresence from '../SkipInitialAnimatePresence';
import {
  ArticleListItemWrapper,
  Article,
  Content,
  StyledHeading,
  InfoWrapper,
  DateCopy,
  StyledMedia,
  MediaWrapper,
} from './styles';

interface Props {
  className?: string;
  article: ContentfulArticleListItem;
  featured?: boolean;
  removeFeaturedMargin?: boolean;
  leftAlignFeaturedMedia?: boolean;
  largeNotFeatured?: boolean;
}

const ArticleListItem = ({
  className,
  article,
  featured,
  removeFeaturedMargin,
  leftAlignFeaturedMedia,
  largeNotFeatured,
}: Props) => {
  const notFeaturedAndWiderThanTall =
    !featured &&
    !largeNotFeatured &&
    article.media.width &&
    article.media.height &&
    article.media.width > article.media.height;
  const aspectRatio = notFeaturedAndWiderThanTall ? '1:1' : '16:9';

  return (
    <SkipInitialAnimatePresence>
      <ArticleListItemWrapper
        className={className}
        href={getArticlePath(article)}
        tracking={
          featured
            ? eventClickFeaturedArticle(article.slug)
            : eventClickArticle(article.slug)
        }
      >
        <Article
          $featured={featured}
          $removeFeaturedMargin={removeFeaturedMargin}
          $leftAlignFeaturedMedia={leftAlignFeaturedMedia}
        >
          <Content>
            <StyledHeading
              level={featured ? 4 : largeNotFeatured ? 5 : 6}
              animate
            >
              {article.title}
            </StyledHeading>
            <InfoWrapper $featured={featured}>
              <UsersCopy users={article.authorsCollection.items} showEllipsis />
              <DividerCopy />
              <DateCopy>{dateIsoToReadable(article.publishDate)}</DateCopy>
            </InfoWrapper>
          </Content>
          <MediaWrapper>
            <StyledMedia media={article.media} aspectRatio={aspectRatio} />
          </MediaWrapper>
        </Article>
      </ArticleListItemWrapper>
    </SkipInitialAnimatePresence>
  );
};

export default ArticleListItem;
