import {pxToRem} from '@/theme/grid';
import {SVGRProps} from '@/utils/svgrProps';

import IconWrapper from '../IconWrapper';
import TickSVG from './icon.svg';

const Tick: React.FC<SVGRProps> = ({
  className,
  width = pxToRem(10),
  height = pxToRem(10),
}) => (
  <IconWrapper width={width} height={height} className={className}>
    <TickSVG width="100%" height="100%" />
  </IconWrapper>
);

export default Tick;
