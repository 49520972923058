import styled, {css} from 'styled-components';

import Copy from '@/components/Copy';

interface Props {
  $showEllipsis?: boolean;
}

export const StyledCopy = styled(Copy)<Props>`
  margin-bottom: 0;

  ${({$showEllipsis}) =>
    $showEllipsis &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}
`;
