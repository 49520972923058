import {motion} from 'framer-motion';
import styled from 'styled-components';

import Social from '@/components/Social';
import {COLOR_ORANGE} from '@/theme/colors';
import {SPACING_XS} from '@/theme/spacings';
import {
  TRANSITION_EASE_CUBIC,
  TRANSITION_SPEED_REGULAR,
} from '@/theme/transitions';
import {Z_INDEX_CONTENT_OVERLAY} from '@/theme/zIndex';

export const ShareToSocialsWrapper = styled.div`
  display: flex;
  gap: ${SPACING_XS};
`;

interface Props {
  $clicked: boolean;
}

export const StyledSocial = styled(Social)<Props>`
  position: relative;

  &:after {
    content: '';
    position: absolute;

    /* Show over border when opened */
    height: calc(100% + 2px);
    width: calc(100% + 2px);
    background: ${COLOR_ORANGE};
    border-radius: 100%;
    transform: scale(${({$clicked}) => ($clicked ? 1 : 0)});
    transition: transform ${TRANSITION_SPEED_REGULAR}s ${TRANSITION_EASE_CUBIC};
  }
`;

export const TickWrapper = styled(motion.span)`
  position: absolute;
  display: flex;
  z-index: ${Z_INDEX_CONTENT_OVERLAY};
`;
