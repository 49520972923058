import {Document} from '@contentful/rich-text-types';

import {Sizes} from '@/components/Heading';

// BLOCKS

export enum BlockName {
  Carousel = 'BlockCarousel',
  Columns = 'BlockColumns',
  DataCircles = 'BlockDataCircles',
  Heading = 'BlockHeading',
  JourneyIntro = 'BlockJourneyIntro',
  LineChart = 'BlockLineChart',
  Media = 'BlockMedia',
  MediaColumns = 'BlockMediaColumns',
  Quote = 'BlockQuote',
  RichText = 'BlockRichText',
  Stat = 'BlockStat',
  StickyMediaContent = 'BlockStickyMediaContent',
  Youtube = 'BlockYoutube',
  YoutubeChapters = 'BlockYoutubeChapters',
}

export interface Block {
  __typename: string;
}

// SHARED

export type theme = 'light' | 'dark';

interface BaseMedia {
  sys: {
    id: string;
  };
  title: string;
  fileName: string;
  contentType: string;
  url: string;
  width: number | null;
  height: number | null;
}

export type Media = Exclude<BaseMedia, 'url'>;

export interface MediaWithDescription extends Media {
  description?: string;
}

export type MediaUrl = Pick<BaseMedia, 'title' | 'url'>;

export interface Link {
  title: string;
  description?: string;
  url?: string;
  page?: PageListItem;
}

export interface LinkWithHeading {
  heading: string;
  link: Link;
}

export interface LinkWithId extends Link {
  sys: {
    id: string;
  };
}

export interface RichTextWithLinksAndMedia {
  entries?: {
    hyperlink: LinkWithId[];
    inline: LinkWithId[];
  };
  assets?: {
    block: Media[];
  };
}

export interface RichText {
  json: Document;
  links?: RichTextWithLinksAndMedia;
}

export interface YouTubeVideo {
  id: string;
  title?: string;
  duration?: string;
  thumbnail?: Media;
  caption?: string;
}

export interface YouTubeVideoCollection {
  items: YouTubeVideo[];
}

export type Platform = 'YouTube' | 'Linkedin';

export interface Social {
  url: string;
  platform: Platform;
}

export interface PagedItems<T> {
  items: T[];
  total: number;
}

// GLOBALS

export interface Header {
  openedLabel: string;
  hoveringLabel: string;
  linksCollection: {
    items: Link[];
  };
  subLinksCollection: {
    items: Link[];
  };
  socialsCollection: {
    items: Social[];
  };
  media: Media;
  subNavBackToTopLabel: string;
}

export interface Footer {
  discoverNextHeading: string;
  copyright: string;
  linksCollection: {
    items: Link[];
  };
  socialsCollection: {
    items: Social[];
  };
}

export interface Globals {
  siteTitle: string;
  titleTemplate: string;
  siteDescription: string;
  baseSiteUrl: string;
  shareAsset: MediaUrl;
  siteTwitterUsername: string;
  header: Header;
  footer: Footer;
  articlePageSize: number;
  articleHeaderLabel: string;
  articleFootnotesHeader: string;
  articleTopicsHeader: string;
  relatedArticlesHeader: string;
  youTubeChapterCurrentHeading: string;
  youTubeChapterPrologueHeading: string;
  youTubePlayVideoCopy: string;
  usersPrefix?: string;
  fieldRequiredErrorLabel: string;
  preview?: string;
}

// PAGES

export interface Thinking {
  filtersLabel: string;
  viewAllLabel: string;
  featuredArticlesHeading: string;
  featuredArticlesCollection: {
    items: ArticleListItem[];
  };
}

export interface Archive {
  paginationPreviousLabel: string;
  paginationNextLabel: string;
}

export interface JoinUs {
  jobsHeading: string;
  filtersLabel: string;
  noJobsFound: string;
}

export interface JobForm {
  heading: RichText;
  subHeading: string;
  textFieldPlaceholder?: string;
  emailFieldPlaceholder?: string;
  phoneFieldPlaceholder?: string;
  uploadFileButtonLabel: string;
  accordionLabel: string;
  submitButtonLabel: string;
  formSubmittedSuccessHeading: string;
  formSubmittedSuccessCopy: string;
  missingFieldsLabel: string;
}

export interface FourZeroFour {
  heading: RichText;
  description: string;
  link: Link;
}

export type PageType = Thinking | Archive | JoinUs | JobForm | FourZeroFour;

export interface Page<T = PageType> {
  slug: string;
  title?: string;
  headerLabel: string;
  shareAsset?: MediaUrl;
  content: T;
  blocksCollection: {
    items: Block[];
  };
  footerCta: LinkWithHeading;
  metaDescription: string;
}

export type PageListItem = Pick<Page, 'slug'>;

// USERS

export interface User {
  name: string;
  titleRichText: RichText;
  summary?: string;
  media?: Media;
  headingLevel: Sizes;
}

// STATS

export interface Stat {
  statNumber: string;
  legend: string;
}

export interface StatsCollections {
  items: Stat[];
}

// ARTICLES

export interface Topic {
  slug: string;
  title: string;
}

export interface Article {
  slug: string;
  topicsCollection: {
    items: Topic[];
  };
  relatedTopic: Topic;
  publishDate: string;
  title: string;
  media: Media;
  blocksCollection: {
    items: Block[];
  };
  authorsCollection: {
    items: User[];
  };
  metaDescription: string;
}

export type ArticleListItem = Pick<
  Article,
  | 'slug'
  | 'topicsCollection'
  | 'relatedTopic'
  | 'publishDate'
  | 'title'
  | 'media'
  | 'authorsCollection'
>;

// JOURNEY TIMELINE

export type JourneyLineDirection = 'left' | 'right';

export interface JourneyLine {
  from: JourneyLineDirection;
  to: JourneyLineDirection;
}

export interface JourneyLabelType {
  title: string;
  subTitle?: string;
  color?: string;
}

export interface Chapter {
  chapterName: string;
  title: string;
  description: RichText;
  elements: TimelineElement[];
  era: string;
  line: JourneyLine;
}

export interface ChaptersCollection {
  items: TimelineElement[];
}

export interface TimelineElement {
  era: string;
  line: JourneyLine;
  title: RichText;
  chapterName: string;
  chapter: number;
  label: JourneyLabelType;
  image?: MediaWithDescription;
  inline?: boolean;
  fullWidthTitle?: boolean;
  copy: RichText;
  year: string;
  imageFloatingOverTitle?: boolean;
  navigationTitle?: string;
  mediaAlignRight?: boolean;
  tiltMedia?: boolean;
  imageMobileAlternative?: Media;
}

export interface StickyMediaContent {
  title: string;
  copy: string;
  media: Media;
}
export interface ParagraphWithMedia {
  heading: string;
  headingLevel: Sizes;
  eyebrow?: string;
  copy: RichText;
  media?: Media;
}

export interface MilestoneType {
  milestoneHeading: RichText;
  milestoneCopy?: RichText;
  year?: string;
  relatedMedia: Media;
  imageInline?: boolean;
  isCarMedia?: boolean;
}
