import styled from 'styled-components';

import Copy from '@/components/Copy';
import {COLOR_BLACK} from '@/theme/colors';
import {SPACING_S, SPACING_XS} from '@/theme/spacings';

export const TopicsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${SPACING_S};
  margin-top: ${SPACING_XS};
`;

export const StyledCopy = styled(Copy)`
  margin: 0 0 ${SPACING_XS};
  border-bottom: 1px solid ${COLOR_BLACK};
`;
