import {pxToRem} from '@/theme/grid';
import {SVGRProps} from '@/utils/svgrProps';

import IconWrapper from '../IconWrapper';
import TwitterSVG from './icon.svg';

const Twitter: React.FC<SVGRProps> = ({
  className,
  width = pxToRem(20),
  height = pxToRem(20),
}) => (
  <IconWrapper width={width} height={height} className={className}>
    <TwitterSVG width="100%" height="100%" />
  </IconWrapper>
);

export default Twitter;
