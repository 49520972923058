import {serifSizes} from '@/components/Copy';

import {StyledCopy} from './styles';

interface Props {
  className?: string;
  serifSize?: serifSizes;
}

const DividerCopy = ({className, serifSize}: Props) => (
  <StyledCopy className={className} serifSize={serifSize}>
    &#183;
  </StyledCopy>
);

export default DividerCopy;
