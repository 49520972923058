import {useMemo} from 'react';

import {serifSizes} from '@/components/Copy';
import {User} from '@/content/cms/types';
import {useGlobals} from '@/contexts/globals';

import {StyledCopy} from './styles';

const USER_SEPARATOR = ', ';

interface Props {
  className?: string;
  users: User[];
  showEllipsis?: boolean;
  serifSize?: serifSizes;
}

const UsersCopy = ({
  className,
  users,
  showEllipsis,
  serifSize = 'regular',
}: Props) => {
  const {usersPrefix} = useGlobals();
  const joinedUsers = useMemo(
    () => users.map((user) => user.name).join(USER_SEPARATOR),
    [users],
  );

  return (
    <StyledCopy
      className={className}
      serifSize={serifSize}
      $showEllipsis={showEllipsis}
    >
      {usersPrefix ? `${usersPrefix}  ${joinedUsers}` : joinedUsers}
    </StyledCopy>
  );
};

export default UsersCopy;
