import React from 'react';

import Arrow from '@/components/icons/Arrow';
import ShareToSocials from '@/components/ShareToSocials';
import UsersCopy from '@/components/UsersCopy';
import {getSlugPath, SLUG_THINKING} from '@/config/routes';
import {eventClickArticleBack} from '@/config/tracking';
import {Article} from '@/content/cms/types';
import {dateIsoToReadable} from '@/utils/date';
import {Direction} from '@/utils/svgrProps';

import {
  ArticlePageHeaderWrapper,
  StyledButton,
  MetaWrapper,
  StyledHeading,
  InfoWrapper,
  StyledCopy,
} from './styles';

interface Props {
  article: Article;
}

const ArticlePageHeader = ({article}: Props) => (
  <ArticlePageHeaderWrapper>
    <StyledButton
      href={getSlugPath(SLUG_THINKING)}
      tracking={eventClickArticleBack(article.title)}
      round
      animateArrow
    >
      <Arrow direction={Direction.Left} />
    </StyledButton>
    <StyledHeading level={article.title.length > 64 ? 2.5 : 2} animate>
      {article.title}
    </StyledHeading>
    <MetaWrapper>
      <InfoWrapper>
        <UsersCopy users={article.authorsCollection.items} />
        <StyledCopy>{dateIsoToReadable(article.publishDate)}</StyledCopy>
      </InfoWrapper>
      <ShareToSocials small />
    </MetaWrapper>
  </ArticlePageHeaderWrapper>
);

export default ArticlePageHeader;
