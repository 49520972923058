import styled from 'styled-components';

import Copy from '@/components/Copy';
import Link from '@/components/Link';
import Source from '@/components/Source';
import {COLOR_BLACK} from '@/theme/colors';
import {SPACING_XS} from '@/theme/spacings';
import {TYPE_SIZE_SANS_REGULAR} from '@/theme/type';

export const FootnotesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledCopy = styled(Copy)`
  margin: 0 0 ${SPACING_XS};
  border-bottom: 1px solid ${COLOR_BLACK};
`;

export const StyledLink = styled(Link)`
  ${TYPE_SIZE_SANS_REGULAR}
`;

export const StyledSource = styled(Source)`
  margin-top: ${SPACING_XS};
`;
