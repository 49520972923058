import {StyledSectionGutter} from './styles';

export type SectionGutterType = 'small' | 'medium' | 'large' | 'article';

interface SectionGutterProps {
  className?: string;
  size: SectionGutterType;
  justLeft?: boolean;
  justRight?: boolean;
}

const SectionGutter: React.FC<SectionGutterProps> = ({
  className,
  size,
  justLeft,
  justRight,
  children,
}) => (
  <StyledSectionGutter
    className={className}
    $size={size}
    $justLeft={justLeft}
    $justRight={justRight}
  >
    {children}
  </StyledSectionGutter>
);

export default SectionGutter;
