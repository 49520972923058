import {useState} from 'react';

import {
  getCurrentUrlAndCopyToClipboard,
  getShareUrl,
  SocialPlatforms,
} from '@phntms/react-share';
import {AnimatePresence} from 'framer-motion';
import {useRouter} from 'next/router';

import Copy from '@/components/icons/Copy';
import Facebook from '@/components/icons/Facebook';
import Linkedin from '@/components/icons/Linkedin';
import Tick from '@/components/icons/Tick';
import Twitter from '@/components/icons/Twitter';
import Social from '@/components/Social';
import {eventClickShareToSocial} from '@/config/tracking';
import {useGlobals} from '@/contexts/globals';
import {TRANSITION_SPEED_FAST} from '@/theme/transitions';

import {ShareToSocialsWrapper, StyledSocial, TickWrapper} from './styles';

const SUPPORTED_PLATFORMS = [
  SocialPlatforms.Facebook,
  SocialPlatforms.Linkedin,
  SocialPlatforms.Twitter,
];

interface Props {
  small?: boolean;
}

const ShareToSocials = ({small}: Props) => {
  const {baseSiteUrl} = useGlobals();
  const router = useRouter();

  const icon = (platform: SocialPlatforms) => {
    switch (platform) {
      case SocialPlatforms.Facebook:
        return <Facebook />;

      case SocialPlatforms.Linkedin:
        return <Linkedin />;

      case SocialPlatforms.Twitter:
        return <Twitter />;
    }
  };

  const [copied, setCopied] = useState<NodeJS.Timeout>();
  const copy = () => {
    getCurrentUrlAndCopyToClipboard();

    // Show copied state for limited time
    if (!copied) setCopied(setTimeout(() => setCopied(undefined), 1500));
  };

  return (
    <ShareToSocialsWrapper>
      {SUPPORTED_PLATFORMS.map((platform, index) => (
        <Social
          key={index}
          url={getShareUrl(platform, {url: baseSiteUrl + router.asPath})}
          tracking={eventClickShareToSocial(SocialPlatforms[platform])}
          label={SocialPlatforms[platform]}
          small={small}
        >
          {icon(platform)}
        </Social>
      ))}
      <StyledSocial
        onClick={() => copy()}
        tracking={eventClickShareToSocial('Copy')}
        label={'Copy'}
        $clicked={!!copied}
        small={small}
      >
        <Copy />
        <AnimatePresence>
          {!!copied && (
            <TickWrapper
              initial={{scale: 0}}
              animate={{scale: 1, transition: {delay: TRANSITION_SPEED_FAST}}}
              exit={{scale: 0}}
              transition={{duration: TRANSITION_SPEED_FAST}}
            >
              <Tick />
            </TickWrapper>
          )}
        </AnimatePresence>
      </StyledSocial>
    </ShareToSocialsWrapper>
  );
};

export default ShareToSocials;
