import Button from '@/components/Button';
import {getArchivedArticlesPath} from '@/config/routes';
import {eventClickFeaturedArticleTopic} from '@/config/tracking';
import {ArticleListItem} from '@/content/cms/types';

import {TopicsWrapper, StyledCopy, ButtonsWrapper} from './styles';

interface Props {
  heading: string;
  article: ArticleListItem;
}

const Topics = ({heading, article}: Props) => (
  <TopicsWrapper>
    <StyledCopy>{heading}</StyledCopy>
    <ButtonsWrapper>
      {article.topicsCollection.items.map((topic) => (
        <Button
          key={topic.slug}
          href={getArchivedArticlesPath(topic)}
          secondary
          tracking={eventClickFeaturedArticleTopic(article.slug, topic.title)}
          small
        >
          {topic.title}
        </Button>
      ))}
    </ButtonsWrapper>
  </TopicsWrapper>
);

export default Topics;
