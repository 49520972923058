import styled, {css} from 'styled-components';

import {gridGutters} from '@/theme/grid';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
} from '@/theme/mediaQueries';
import {SPACING_L, SPACING_M, SPACING_XL} from '@/theme/spacings';

interface Props {
  $smallTopMargin?: boolean;
  $removeBottomMargin?: boolean;
}

export const ArticlesWrapper = styled.div<Props>`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-column-gap: ${gridGutters(1)};
  grid-row-gap: ${SPACING_L};
  margin-top: ${SPACING_M};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin-top: ${SPACING_L};

    /* Minmax used to prevent grid items from overflowing column */
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  ${({$smallTopMargin}) =>
    $smallTopMargin
      ? css`
          margin-top: ${SPACING_M};

          @media ${QUERY_GREATER_THAN_MOBILE} {
            margin-top: ${SPACING_L};
          }
        `
      : css`
          margin-top: ${SPACING_L};

          @media ${QUERY_GREATER_THAN_TABLET} {
            margin-top: ${SPACING_XL};
          }
        `};

  ${({$removeBottomMargin}) =>
    !$removeBottomMargin &&
    css`
      margin-bottom: ${SPACING_L};

      @media ${QUERY_GREATER_THAN_TABLET} {
        margin-bottom: ${SPACING_XL};
      }
    `};
`;
