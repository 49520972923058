import {Link} from '@/content/cms/types';

import {FootnotesWrapper, StyledCopy, StyledSource} from './styles';

export interface Props {
  heading: string;
  links: Link[];
}

const Footnotes = ({heading, links}: Props) => (
  <FootnotesWrapper>
    <StyledCopy>{heading}</StyledCopy>
    {links.map((link, index) => (
      <StyledSource key={index} link={link} />
    ))}
  </FootnotesWrapper>
);

export default Footnotes;
