import styled, {css} from 'styled-components';

import Copy from '@/components/Copy';
import Heading from '@/components/Heading';
import Link from '@/components/Link';
import Media from '@/components/Media';
import {BORDER_RADIUS_SMALL, onFocusOutline} from '@/theme/borders';
import {gridGutters} from '@/theme/grid';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
} from '@/theme/mediaQueries';
import {
  SPACING_M,
  SPACING_XL,
  SPACING_S,
  SPACING_L,
  SPACING_XXS,
} from '@/theme/spacings';
import {TRANSITION_EASE_ON, TRANSITION_SPEED_SLOW} from '@/theme/transitions';

export const Content = styled.div`
  margin-top: ${SPACING_M};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin-top: ${SPACING_S};
  }
`;

export const MediaWrapper = styled.div`
  border-radius: ${BORDER_RADIUS_SMALL};
  overflow: hidden;
`;

export const StyledMedia = styled(Media)`
  transition: transform ${TRANSITION_SPEED_SLOW}s ${TRANSITION_EASE_ON};
`;

export const ArticleListItemWrapper = styled(Link)`
  display: block;

  /* Round outline edges */
  border-radius: ${BORDER_RADIUS_SMALL};

  ${onFocusOutline()}

  /* Reset default link styles */
  color: inherit;
  text-decoration: none;

  &:hover ${StyledMedia} {
    transform: scale(1.05);
  }
`;

interface Props {
  $featured?: boolean;
  $leftAlignFeaturedMedia?: boolean;
  $removeFeaturedMargin?: boolean;
}

export const Article = styled.article<Props>`
  ${({$featured, $leftAlignFeaturedMedia, $removeFeaturedMargin}) =>
    $featured
      ? css`
          display: flex;
          flex-direction: column-reverse;

          ${!$removeFeaturedMargin &&
          css`
            margin: ${SPACING_L} 0;

            @media ${QUERY_GREATER_THAN_MOBILE} {
              margin: ${SPACING_XL} 0 0;
            }
          `}

          @media ${QUERY_GREATER_THAN_MOBILE} {
            width: 100%;
            display: grid;
            gap: ${gridGutters(1)};
            align-items: center;
          }

          ${$leftAlignFeaturedMedia
            ? css`
                @media ${QUERY_GREATER_THAN_MOBILE} {
                  grid-template-columns: ${gridGutters(9)} ${gridGutters(7)};

                  > ${Content} {
                    order: 2;
                  }

                  > ${MediaWrapper} {
                    order: 1;
                  }
                }

                @media ${QUERY_GREATER_THAN_TABLET} {
                  grid-template-columns: ${gridGutters(11)} ${gridGutters(9)};
                }
              `
            : css`
                @media ${QUERY_GREATER_THAN_MOBILE} {
                  grid-template-columns: ${gridGutters(7)} ${gridGutters(9)};
                }

                @media ${QUERY_GREATER_THAN_TABLET} {
                  grid-template-columns: ${gridGutters(9)} ${gridGutters(11)};
                }
              `}
        `
      : css`
          display: flex;
          flex-direction: column-reverse;
        `}
`;

export const StyledHeading = styled(Heading)`
  margin: 0 0 ${SPACING_XXS};
`;

export const InfoWrapper = styled.div<Props>`
  display: flex;

  ${({$featured}) =>
    $featured &&
    css`
      @media ${QUERY_GREATER_THAN_TABLET} {
        max-width: ${gridGutters(6)};
      }
    `}
`;

export const DateCopy = styled(Copy)`
  white-space: nowrap;
  margin-bottom: 0;
`;
