import styled from 'styled-components';

import Button from '@/components/Button';
import Copy from '@/components/Copy';
import Heading from '@/components/Heading';
import {gridGutters} from '@/theme/grid';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
} from '@/theme/mediaQueries';
import {SPACING_M, SPACING_L, SPACING_XL} from '@/theme/spacings';

export const ArticlePageHeaderWrapper = styled.header`
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${QUERY_GREATER_THAN_MOBILE} {
    padding-left: ${gridGutters(4)};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    padding-left: ${gridGutters(5)};
  }
`;

export const StyledButton = styled(Button)`
  margin-bottom: ${SPACING_M};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    position: absolute;
    left: 0;
  }
`;

export const MetaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${SPACING_L};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: ${SPACING_L};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    margin-bottom: ${SPACING_XL};
  }
`;

export const InfoWrapper = styled.div`
  margin-bottom: ${SPACING_M};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin-bottom: 0;
  }
`;

export const StyledCopy = styled(Copy)`
  /* !important required to override Copy margin */
  margin-bottom: 0 !important;
`;

export const StyledHeading = styled(Heading)`
  margin: 0 0 ${SPACING_M};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin: 0 0 ${SPACING_L};
  }
`;
