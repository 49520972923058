import {ParsedUrlQuery} from 'querystring';

import {useMemo} from 'react';

import {GetStaticPaths, GetStaticProps} from 'next';

import ArticlePageContent from '@/components/ArticlePageContent';
import ArticlePageHeader from '@/components/ArticlePageHeader';
import Articles from '@/components/Articles';
import {BlockQuoteProps} from '@/components/Blocks/BlockQuote';
import {BlockRichTextProps} from '@/components/Blocks/BlockRichText';
import {BlockStatProps} from '@/components/Blocks/BlockStat';
import Heading from '@/components/Heading';
import Media from '@/components/Media';
import PageLayout from '@/components/PageLayout';
import SectionGutter from '@/components/SectionGutter';
import {getArticlePath} from '@/config/routes';
import {getArticle, getArticles, getGlobals, overrideMeta} from '@/content/cms';
import {
  Article,
  ArticleListItem,
  BlockName,
  Globals,
  Link,
} from '@/content/cms/types';
import useFetch from '@/hooks/useFetch';

export interface Props {
  globals: Globals;
  article: Article;
  relatedArticles: ArticleListItem[] | null;
  slug: string;
}

export const fetchPropsWithParams =
  (slug: string) => async (token?: string) => {
    const globals = await getGlobals(token);
    const article = await getArticle(slug, token);

    overrideMeta(article, globals);

    const relatedArticles = article.relatedTopic
      ? await getArticles(3, article.relatedTopic.slug, [article.slug], token)
      : null;

    return {globals, article, relatedArticles} as Props;
  };

const ArticlePage = (props: Props) => {
  const {globals, article, relatedArticles} = useFetch<Props>(
    props,
    fetchPropsWithParams(props.slug),
  );
  const links = useMemo(() => {
    const richTextLinks = article.blocksCollection.items
      .filter((block) => block.__typename === BlockName.RichText)
      .map((block) => {
        const blockRichText = block as BlockRichTextProps;
        return blockRichText.text.links?.entries;
      })
      .reduce(
        (memo, entry) =>
          entry ? memo.concat([...entry.hyperlink, ...entry.inline]) : [],
        [] as Link[],
      );

    const quoteOrStatLinks = article.blocksCollection.items
      .filter(
        (block) =>
          (block.__typename === BlockName.Quote ||
            block.__typename === BlockName.Stat) &&
          !!(block as BlockQuoteProps | BlockStatProps).source,
      )
      .map((block) => (block as BlockQuoteProps | BlockStatProps).source);

    const linksWithoutDuplicates = [
      ...richTextLinks,
      ...quoteOrStatLinks,
    ].filter(
      (link, index, self) =>
        self.findIndex((l) => l.title === link.title) === index,
    );

    return linksWithoutDuplicates;
  }, [article]);

  const relatedArticlesHeaderWithTopic =
    article.relatedTopic &&
    globals.relatedArticlesHeader.replace(
      '[topic]',
      article.relatedTopic.title,
    );

  return (
    <PageLayout
      headerLabel={globals.articleHeaderLabel}
      title={article.title}
      globals={globals}
      shareMedia={article.media}
    >
      <ArticlePageHeader article={article} />
      <Media media={article.media} aspectRatio="3:2" enableInnerLocomotive />
      <SectionGutter size="article">
        <ArticlePageContent article={article} links={links} />
      </SectionGutter>
      {relatedArticles && !!relatedArticles.length && (
        <SectionGutter size="small">
          <Heading level={3} animate>
            {relatedArticlesHeaderWithTopic}
          </Heading>
          <Articles
            articles={relatedArticles}
            smallTopMargin
            removeBottomMargin
          />
        </SectionGutter>
      )}
    </PageLayout>
  );
};

interface Params extends ParsedUrlQuery {
  slug: string;
}

export const getStaticProps: GetStaticProps<Props, Params> = async (
  context,
) => {
  const {slug} = context.params as Params;
  const fetchProps = fetchPropsWithParams(slug);
  const props = await fetchProps();
  return {props: {...props, slug}};
};

export const getStaticPaths: GetStaticPaths = async () => {
  const articles = await getArticles();
  return {
    paths: articles.map((article) => getArticlePath(article)),
    fallback: false,
  };
};

export default ArticlePage;
